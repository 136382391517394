import { useState, useEffect } from "react"
import Overlay from "../../shared/components/Overlay"
import { Button } from "../../shared/components/Buttons"
import axios from "axios"

export default function ItemDetail (props) {

    const [item, getItem] = useState()
    const [displayDeleteModal, setDeleteModal] = useState(false)
    const closeDeleteModal = () => setDeleteModal(false)

    const url = 'https://dash-api-kappa.vercel.app/inventory/'

    useEffect(() => {
        axios.get(`${url}${props.query}`)
        .then(({data}) => getItem(data))
        .catch(error => console.error(`${error}`))
    }, [props.query])

    const deleteThisItem = (itemId) => {

        axios
            .delete('https://dash-api-kappa.vercel.app/inventory', { data: { id: itemId,
            status: 'removed-test' } })
            .then(({data}) => { console.log(data) })
            .catch(err => { console.error(err) })

        closeDeleteModal();
        
    }

   if (!item) return <p className="loading">Loading</p>

   else return (
        <>
            <h3 style={{textTransform: "uppercase"}}>{item.category}</h3>
            <h1 style={{textTransform: "capitalize"}}>{item.brand} {item.model !='NA' ? item.model : ''} {item.type}</h1>
            <div><Button label="Delete" onClick={() => { setDeleteModal(true) }}  /></div>
            <h2>Overview</h2>
            <p>Original Cost: {Intl.NumberFormat('en-CO', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(item.originalCost)}</p>
            <p>Date of Acquisition: {new Date(item.dateOfAcquisition).toDateString()}</p>
            <p>Life Expectancy: {item.lifeExpectancy} {item.lifeExpectancy === 1 ? 'Year' : "Years"}</p>

            <h2>Specs</h2>
            <p>Dimensions (cm): W {item.specifications.width} x H {item.specifications.height} x D {item.specifications.depth}</p>
            <p>Weight: {item.specifications.weight} g</p>
            <p>Color: {item.specifications.color != '' ? item.specifications.color.join(', ') : "Undefined"}</p>
            <p>Materials: {item.specifications.material  != '' ? item.specifications.material.join(', ') : "Undefined" }</p>

            <Overlay isOpen={displayDeleteModal} close={closeDeleteModal}>     
                <h3>Are you sure you want to delete this?</h3><br></br> 
                <h2 style={{textTransform: "capitalize"}}>{item.brand} {item.model !='NA' ? item.model : ''} {item.type}</h2> 
                <Button label="Delete" onClick={() => { deleteThisItem(item._id) }}   />
                <Button label="Cancel" onClick={() => { closeDeleteModal() }}   />
            </Overlay>
        </>
    )
}