function Card (props) {
    return(
        <div className="card">
            <p>THIS <b>IS</b> A CARD </p>
        </div>
    )
}

function PlantCard (props) {
    return(
        <div className="card plant-index" onClick={props.onClick}>
            <p>{props.plantFamily}</p> 
            <p>{props.plantGenus} {props.plantSpecies}</p>
        </div>
    )
}

function ItemCard (props) {
    return(
        <div className="card plant-index" onClick={props.onClick}>
            <p>{props.type}</p> 
            <p>{props.brand.toUpperCase() !== 'NA' ? props.brand : 'Generic'} {props.model.toUpperCase() !== 'NA' ? props.model : ''}</p>
        </div>
    )
}

export { Card, PlantCard, ItemCard }