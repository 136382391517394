import { useEffect, useState } from "react"
import axios from "axios"
import { Button } from "../../shared/components/Buttons"
import Toolbar from "../../shared/components/Toolbar"
import Overlay from "../../shared/components/Overlay"
import InventoryNewForm from "./ItemNew.form"
import { ItemCard } from "../../shared/components/Cards"
import ItemDetail from "./ItemDetail"
import groupBy from "../../shared/helpers/groupBy"

export default function Inventory () {

    const [items, getItems] = useState([])
    const [displayForm, setDisplayForm] = useState(false)

    const [displayDoc, setDisplayDoc] = useState(false)
    const [doc, getDoc] = useState(null)

    const closeForm = () => setDisplayForm(false)
    const closeDoc = () => setDisplayDoc(false)

    useEffect(() => {
        axios.get(`https://dash-api-kappa.vercel.app/inventory`)
        .then((res) => getItems(res.data))
        .catch(error => console.error(`${error}`))
    }, []);

    const sortedItems = groupBy(items, 'category')

    return(
        <>
            <h1>Inventory</h1>
            <h2>CURRENT <span className="alt">| {items.length}</span> </h2>

            <Toolbar>
                <Button label="New" onClick={() => { setDisplayForm(true) }}  />
                <a href='/'>Filter</a>
                <a href='/'>Sort</a>
            </Toolbar>

            {
                Object.keys(sortedItems).map((key) => {
                    return (
                        <div key={key} className="collection group">
                            <h3>{key} <span className="alt">| { sortedItems[key].length }</span></h3>
                            {sortedItems[key].map((item) => {
                                return (
                                    <ItemCard key={item._id} type={item.type} brand={item.brand} model={item.model} onClick={() => {setDisplayDoc(true); getDoc(item._id)}}/>
                                )
                            })}
                        </div>
                    )
                })
            }

            <br></br>
            <br></br>
            <br></br>

            <Overlay isOpen={displayForm} close={closeForm}>     
                <h3>CREATE NEW ITEM</h3><br></br>  
                <InventoryNewForm close={closeForm} />
            </Overlay>

            <Overlay isOpen={displayDoc} close={closeDoc}>     
                {doc != null ? <ItemDetail query={doc} /> : null}
            </Overlay>
        </>
    )
}