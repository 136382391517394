import { useInput } from '../../shared/helpers/useInput'
import axios from 'axios'

export default function InventoryNewForm (props) {

    const { value:category, bind:bindCategory, reset:resetCategory } = useInput('')
    const { value:type, bind:bindType, reset:resetType } = useInput('')
    const { value:brand, bind:bindBrand, reset:resetBrand } = useInput('')
    const { value:model, bind:bindModel, reset:resetModel } = useInput('')
    const { value:dateOfAcquisition, bind:bindDateOfAcquisition, reset:resetDateOfAcquisition } = useInput(new Date().toISOString().substring(0, 10))
    const { value:lifeExpentacy, bind:bindLifeExpentacy, reset:resetLifeExpentacy } = useInput(1)
    const { value:originalCost, bind:bindOriginalCost, reset:resetOriginalCost } = useInput(0)
    const { value:specWidth, bind:bindSpecWidth, reset:resetSpecWidth } = useInput(0)
    const { value:specHeight, bind:bindSpecHeight, reset:resetSpecHeight } = useInput(0)
    const { value:specDepth, bind:bindSpecDepth, reset:resetSpecDepth } = useInput(0)
    const { value:specWeight, bind:bindSpecWeight, reset:resetSpecWeight } = useInput(0)
    const { value:specColor, bind:bindSpecColor, reset:resetSpecColor } = useInput('')
    const { value:specMaterial, bind:bindSpecMaterial, reset:resetSpecMaterial } = useInput('')
    
    const handleSubmit = (e) => {
        e.preventDefault();

        const item = { 
            category: category, type: type, brand: brand, model: model,
            dateOfAcquisition: dateOfAcquisition, lifeExpectancy: lifeExpentacy, originalCost: originalCost, 
            specifications: {width: specWidth, height: specHeight, depth: specDepth, weight: specWeight, color: specColor, material: specMaterial}
        }

        console.log(item)

        // Reset State  
        resetCategory()
        resetType()
        resetBrand()
        resetModel()
        resetDateOfAcquisition()
        resetLifeExpentacy()
        resetOriginalCost()
        resetSpecWidth()
        resetSpecHeight()
        resetSpecDepth()
        resetSpecWeight()
        resetSpecColor()
        resetSpecMaterial()

        props.close();

        axios
            .post('https://dash-api-kappa.vercel.app/inventory', item)
            .then(({data}) => { console.log(data) })
            .catch(err => { console.error(err) })
    }

    return(
        <form name="InventoryNewForm" id="InventoryNewForm" onSubmit={handleSubmit}>

            <div className='col-2'>
                <div className="fieldGroup">
                    <label htmlFor="category">Category *</label>
                    <input type="text" title="category" {...bindCategory} autoFocus/>
                </div>
                <div className="fieldGroup">
                    <label htmlFor="type">Type *</label>
                    <input type="text" title="type" {...bindType} />
                </div> 
            </div>
            <div className='col-2'>
                <div className="fieldGroup">
                    <label htmlFor="brand">Brand</label>
                    <input type="text" title="brand" {...bindBrand} />
                </div> 
                <div className="fieldGroup">
                    <label htmlFor="model">Model</label>
                    <input type="text" title="model" {...bindModel} />
                </div>    
            </div>
            <br></br>

            <div className='col-2'>
                <div className="fieldGroup">
                    <label htmlFor="originalCost">Original Cost</label>
                    <input type="number" title="originalCost" min="0" {...bindOriginalCost} />
                </div>
            </div>
            <br></br>

            <div className='col-2'>
                <div className="fieldGroup">
                    <label htmlFor="dateOfAcquisition">Date of Acquisition</label>
                    <input type="date" title="dateOfAcquisition"  {...bindDateOfAcquisition} />
                </div>   
                <div className="fieldGroup">
                    <label htmlFor="lifeExpectancy">Life Expectancy</label>
                    <input type="text" title="lifeExpectancy" {...bindLifeExpentacy} />
                </div>   
            </div>   
            <br></br>
            <div className='col-4'>
                <div className="fieldGroup">
                    <label htmlFor="width">Width</label>
                    <input type="number" title="width" min="0" {...bindSpecWidth} />
                </div>  
                <div className="fieldGroup">
                    <label htmlFor="height">Height</label>
                    <input type="number" title="height" min="0" {...bindSpecHeight} />
                </div>  
                <div className="fieldGroup">
                    <label htmlFor="depth">Depth</label>
                    <input type="number" title="depth" min="0" {...bindSpecDepth} />
                </div> 
                <div className="fieldGroup">
                <label htmlFor="weight">Weight</label>
                <input type="number" title="weight" min="0" {...bindSpecWeight} />
            </div> 
            </div>
            
            <br></br>
              
            <div className="fieldGroup">
                <label htmlFor="color">Color</label>
                <input type="text" title="color" {...bindSpecColor} />
            </div>  
            <div className="fieldGroup">
                <label htmlFor="material">Material(s)</label>
                <input type="text" title="material" {...bindSpecMaterial} />
            </div>  

            <button className="main">Submit</button> 
        </form>
    )
}
