export default function Overlay (props) {

    return (
        <>
            {
                props.isOpen === true &&
                <>
                <div className="overlay-background overlay-open" onClick={props.close}></div>
                <div className="overlay overlay-open">
                    {/* <span className="close" onClick={props.close}>X</span> */}
                    <div className="content">{props.children}</div>
                </div>
                </>
            }

            {
                props.isOpen === false &&
                <>
                <div className="overlay overlay-closed">
                    {/* <span className="close" onClick={props.close}>X</span> */}
                    <div className="content">{props.children}</div>
                </div>
                </>
            }
       </>
    )

}