import axios from 'axios'
import { sha256 } from 'js-sha256'
import { useInput } from '../../shared/helpers/useInput'

export default function LogIn ({ setToken }) {

    const { value:username, bind:bindUsername, reset:resetUsername } = useInput('')
    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('')

    const handleSubmit = (e) => {
      e.preventDefault();

      const credentials = {
        username: username,
        password: sha256(password),
      }

      axios.post('https://dash-api-kappa.vercel.app/authentication', credentials)
      .then(({data}) => setToken(data))
      .catch(error => console.error(`${error}`))

      resetPassword()
      resetUsername()

    }

    return (
      <>
        <h1>Welcome to DASH</h1>
        <form name="loginForm" id="loginForm" onSubmit={handleSubmit}>
          <div className='fieldGroup'>
            <label htmlFor='username'>Username</label>
            <input type='text' name='username' {...bindUsername} autoFocus />
          </div>
          <div className='fieldGroup'>
            <label htmlFor='password'>Password</label>
            <input type='password' name='password' {...bindPassword} />
          </div>
          <button>Submit</button>
        </form>
      </>
    )
}