import { useEffect, useState } from "react"
import axios from "axios"

import groupBy from "../../shared/helpers/groupBy"
import { provisionCalc } from "../../shared/helpers/finance"

export default function Finance () {
    
    const [items, getItems] = useState([])

    useEffect(() => {
        axios.get(`https://dash-api-kappa.vercel.app/inventory`)
        .then((res) => getItems(res.data))
        .catch(error => console.error(`${error}`))
    }, []);

    const sortedItems = groupBy(items, 'category')
    const grandTotal = items.map((i) => provisionCalc(i.originalCost, i.lifeExpectancy)).reduce((acc,current) => acc + current, 0)

    return (
        <>
            <h2>PROVISIONS <span className="alt">| {Intl.NumberFormat('en-CO', {style: 'currency', currency: 'USD', minimumFractionDigits: 0}).format(grandTotal.toFixed())}</span> </h2>
            <div className="list key-value">
                <div className="key-value-title">
                    <span>Fund</span>
                    <span>Amount</span>
                </div>
                {
                Object.keys(sortedItems).map((key) => {
                    
                    const provisionArray = sortedItems[key].map((i) => provisionCalc(i.originalCost, i.lifeExpectancy))
                    const sum = provisionArray.reduce(
                        (acc, current) => acc + current
                    )

                    return (
                        <div key={key} className="key-value-pair">
                            <p>{key}</p>
                            <p>{Intl.NumberFormat('en-CO').format(sum.toFixed())}</p>
                        </div>
                    )
                })
            }
            </div>
            
        </>
    )
}