import React from 'react'
import axios from 'axios'

export default class PlantdexNewForm extends React.Component {

    constructor(props){

        super(props)

        this.onChangePlantFamily = this.onChangePlantFamily.bind(this)
        this.onChangePlantGenus = this.onChangePlantGenus.bind(this)
        this.onChangePlantSpecies = this.onChangePlantSpecies.bind(this)
        this.onChangePlantLifecycle = this.onChangePlantLifecycle.bind(this)
        this.onChangePlantEdibility = this.onChangePlantEdibility.bind(this)
        this.onChangePlantAssociatedFauna = this.onChangePlantAssociatedFauna.bind(this)
        this.onChangePlantNutritionFacts = this.onChangePlantNutritionFacts.bind(this)
        this.onChangeSeedLifeSpan = this.onChangeSeedLifeSpan.bind(this)

        this.onSubmit = this.onSubmit.bind(this)
        
        this.state = {
            plantFamily: '',
            plantGenus: '',
            plantSpecies: '',
            plantLifecycle: '',
            plantEdibility: [],
            plantAssociatedFauna: [],
            plantNutritionFacts: [],
            seedLifeSpan: 0,
        }

    }

    onChangePlantFamily (e) { this.setState({ plantFamily: e.target.value }) }
    onChangePlantGenus (e) { this.setState({ plantGenus: e.target.value }) }
    onChangePlantSpecies (e) { this.setState({ plantSpecies: e.target.value }) }
    onChangePlantLifecycle (e) { this.setState({ plantLifecycle: e.target.value }) }
    onChangePlantEdibility (e) { this.setState({ plantEdibility: e.target.value }) }
    onChangePlantAssociatedFauna (e) { this.setState({ plantAssociatedFauna: e.target.value }) }
    onChangePlantNutritionFacts (e) { this.setState({ plantNutritionFacts: e.target.value }) }
    onChangeSeedLifeSpan (e) { this.setState({ seedLifeSpan: e.target.value }) }

    onSubmit (e) {

        e.preventDefault()
    
        const plant = {
          plantFamily: this.state.plantFamily,
          plantGenus: this.state.plantGenus,
          plantSpecies: this.state.plantSpecies,
          plantLifecycle: this.state.plantLifecycle,
          plantEdibility: this.state.plantEdibility,
          plantAssociatedFauna: this.state.plantAssociatedFauna,
          plantNutritionFacts: this.state.plantNutritionFacts,
          seedLifeSpan: this.state.seedLifeSpan,
        }
    
        axios
        .post('http://localhost:9810/plants', plant).then(({data}) => { console.log(data) }).catch(err => { console.error(err) })
    
        this.setState ({
            plantFamily: '',
            plantGenus: '',
            plantSpecies: '',
            plantLifecycle: '',
            plantEdibility: [],
            plantAssociatedFauna: [],
            plantNutritionFacts: [],
            seedLifeSpan: 0,
        })

        this.props.close()
    
    }

    render () {

        return(
            <form name="PlantdexNewForm" id="PlantdexNewForm" onSubmit={this.onSubmit}>
                <div className="fieldGroup">
                    <label htmlFor="plantFamily">Family *</label>
                    <input type="text" title="plantFamily" value={this.state.plantFamily} onChange={this.onChangePlantFamily} autoFocus/>
                </div>
                <div className="fieldGroup">
                    <label htmlFor="plantGenus">Genus *</label>
                    <input type="text" title="plantGenus" value={this.state.plantGenus} onChange={this.onChangePlantGenus} />
                </div> 
                <div className="fieldGroup">
                    <label htmlFor="plantSpecies">Species *</label>
                    <input type="text" title="plantSpecies" value={this.state.plantSpecies} onChange={this.onChangePlantSpecies} />
                </div> 
                <div className="fieldGroup">
                    <label htmlFor="plantLifecycle">Lifecycle</label>
                    <input type="text" title="plantLifecycle" value={this.state.plantLifecycle} onChange={this.onChangePlantLifecycle} />
                </div>    
                <div className="fieldGroup">
                    <label htmlFor="plantEdibility">Edibility</label>
                    <input type="text" title="plantEdibility" value={this.state.plantEdibility} onChange={this.onChangePlantEdibility} />
                </div>   
                <div className="fieldGroup">
                    <label htmlFor="plantAssociatedFauna">Associated Fauna</label>
                    <input type="text" title="plantAssociatedFauna" value={this.state.plantAssociatedFauna} onChange={this.onChangePlantAssociatedFauna} />
                </div>   
                <div className="fieldGroup">
                    <label htmlFor="seedLifeSpan">Seed Viability</label>
                    <input type="number" title="seedLifeSpan" value={this.state.seedLifeSpan} onChange={this.onChangeSeedLifeSpan} />
                </div>   
                <button className="main">Submit</button> 
            </form>
        )

    }

}