import './shared/styles/global.css'

import React, { useState } from 'react'

import { ButtonNavbar } from './shared/components/Buttons'

import LogIn from './modules/auth/LogIn'
import Dashboard from './modules/Dashboard'
import Food from './modules/food/Food'
import Finance from './modules/finance/Finance'
import Plantdex from './modules/plantdex/Plantdex'
import Inventory from './modules/inventory/Inventory'

import Footer from './shared/components/Footer'

export default function App () {

  const [ token, setToken ] = useState()
  const [ view, setView ] = useState('dashboard')

  if (!token) { return <LogIn setToken={setToken} /> } 

  else if (token) {

    return (
      <>    
        {
        view === 'dashboard' ? <Dashboard data={token} /> :
        view === 'finance' ? <Finance /> :
        view === 'food' ? <Food /> :
        view === 'plantdex' ? <Plantdex /> :
        view === 'inventory' ? <Inventory /> :
        <Dashboard />
        } 
  
        <Footer> {/* Pending: Turn menu into a looping component */}
          <ButtonNavbar name="dashboard" view={view} onClick={() => setView('dashboard')} />
          <ButtonNavbar name="finance" view={view} onClick={() => setView('finance')} />
          <ButtonNavbar name="inventory" view={view} onClick={() => setView('inventory')} />
        </Footer>
      </>
    )
    
  }

}