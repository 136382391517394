import { useState, useEffect } from "react"
import axios from "axios"

export default function PlantDetail (props) {

    const [plant, getPlant] = useState()

    const url = "https://dash-api-kappa.vercel.app/plants/"

    useEffect(() => {
        axios.get(`${url}${props.query}`)
        .then(({data}) => {getPlant(data[0])})
        .catch(error => console.error(`${error}`))
    }, [props.query])

 
   if (!plant) return <p className="loading">Loading...</p>

   else return (
        <>
            <h3>{plant.family}</h3>
            
            <h1>{plant.genus} {plant.species}</h1>
            <h2>Overview</h2>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laboriosam ex eius nulla magnam earum libero quam modi. Dolor, recusandae temporibus voluptatem rerum non debitis sint deserunt sit aspernatur, libero ad?</p>
            <h2>Traits</h2>
            <p>Lifecycle: {plant.lifecycle === 'A' && 'Annual'}{plant.lifecycle === 'B' && 'Biennial'}{plant.lifecycle === 'C' && 'Perennial'}</p>
            <p>Height: {plant.varieties[0].height} cm</p>
            <p>Spread: {plant.varieties[0].spread} cm</p>
            <p>Days to Maturity: {plant.varieties[0].daysToMaturity}</p>
            <p>{plant.varieties[0].traits}</p>
            <h2>Edibility</h2>
            <p>{plant.varieties[0].edibility != null ? plant.varieties[0].edibility.join(', ') : 'None'}</p>
            <h2>Associated Fauna</h2>
            <p>{plant.varieties[0].associatedFauna != null ? plant.varieties[0].associatedFauna : 'None'}</p>
        </>
    )
}