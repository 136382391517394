import Divider from "./Divider"

export default function Footer (props) {
    return (
        <footer>
            <Divider />
            <nav>
                <ul>
                    {props.children}
                </ul>
            </nav>
        </footer>
    )
} 

 