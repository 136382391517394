import {useEffect, useState} from 'react'

import axios from 'axios'

import { Button } from '../../shared/components/Buttons';
import { PlantCard } from '../../shared/components/Cards';

import PlantdexNewForm from './PlantdexNewForm';
import Toolbar from '../../shared/components/Toolbar';
import Overlay from '../../shared/components/Overlay';
import PlantDetail from './PlantDetail';

export default function Plantdex() {

    const [plants, getPlants] = useState([])
    const [displayForm, setDisplayForm] = useState(false)
    const [displayDoc, setDisplayDoc] = useState(false)
    const [doc, getDoc] = useState()

    const closeForm = () => setDisplayForm(false)
    const closeDoc = () => setDisplayDoc(false)

    const url = "https://dash-api-kappa.vercel.app/"

    useEffect(() => {
        axios.get(`${url}plants`)
        .then((res) => getPlants(res.data))
        .catch(error => console.error(`${error}`))
    }, []);
    
   const list = plants.map (plant =>
        <PlantCard
            key={plant._id}
            plantFamily={plant.family}
            plantGenus={plant.genus}
            plantSpecies={plant.species}
            onClick={() => {setDisplayDoc(true); getDoc(plant.genus + '&' + plant.species)}}
        />        
    )

    return (
        <>
        <h1>Horticulture</h1>
        <p>
            [ ] Code a Navbar Menu Component<br />
            [ ] Update Plant Index<br />
            [ ] Design Index model<br />
            [•] Document seed viability by species<br />
            [ ] Code CRUD<br />
        </p>
        <h3>PLANT INDEX | {list.length}</h3>
        <Toolbar>
            <Button label="New" onClick={() => { setDisplayForm(true) }} />
            <a href='/'>Filter</a>
            <a href='/'>Sort</a>
        </Toolbar>
        {list}

        <Overlay isOpen={displayForm} close={closeForm}>     
            <h1>Create New Plant</h1>           
            <PlantdexNewForm close={closeForm} />
        </Overlay>

        <Overlay isOpen={displayDoc} close={closeDoc}>     
            <PlantDetail query={doc} />
        </Overlay>
        </>
    )

}